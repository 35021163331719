require('datatables.net')
require('datatables.net-bs4')
require('datatables.net-buttons')
require('datatables.net-buttons/js/buttons.colVis.js')
require('datatables.net-buttons/js/buttons.html5.js')

import JSZip from 'jszip'
window.JSZip = JSZip

/**
 * Datatables common options
 */
const commonOptions = {
	pageLength: 100,
	language: {
        decimal: ".",
        thousands: ","
    },
	aButtons : [{
            sExtends: "select_all",
            sButtonText: "Select Filtered",
            fnClick: function (nButton, oConfig, oFlash) {
                var oTT = TableTools.fnGetInstance('dataTable');
                oTT.fnSelectAll(true); // true = Select only filtered rows (true). Optional - default false.
            }
        }],
    dom: 'Bfrtip',
    columnDefs: [{ "orderable": false, "targets": "_all" }],
    buttons: {
        buttons: [
            { extend: 'colvis', className: 'btn-colvis', columns: ':not(.noVis)' },
            /*{ extend: 'excelHtml5', autoFilter: true, sheetName: 'Exported data', text:'EXCEL CURRENT PAGE', exportOptions:{columns: ':not(.not-export)', stripHtml: true}},*/
            { extend: 'excel', text:'EXCEL ALL', exportOptions:{columns: ':not(.not-export)'}, stripHtml: true, action: newExportAction }
        ],
        dom: {
            button: {
                className: 'btn btn-info btn-sm text-white',
            }
        }
    },
    ordering: false,
    order: [],
    orderCellsTop: true,
    cache: false,
    fixedHeader: true,
}

/**
 * Datatables columns default
 */
const defaultColumnsTable = [
    { data: 'all' },
    { data: 'batch_id' },
    { data: 'transaction_number' },
    { data: 'order_number' },
    { data: 'event' },
    { data: 'arena' },
    { data: 'site_purchased' },
    { data: 'type_of_ticket' },
    { data: 'purchase_date' },
    { data: 'event_date' },
    { data: 'time_of_event' },
    { data: 'day_of_event' },
    { data: 'available_d' },
    { data: 'available' },
    { data: 'number_of_tickets_d' },
    { data: 'number_of_tickets' },
    { data: 'notes' },
    { data: 'restrictions' },
    { data: 'facevalue_per_ticket' },
    { data: 'seats' },
    { data: 'listed' },
    { data: 'referral_1' },
    { data: 'referral_2' },
    { data: 'user_code' },
    { data: 'user_name' },
    { data: 'user_deposit' },
    { data: 'sold' },
    { data: 'total_cost_per_ticket' },
    { data: 'total_cost' },
    { data: 'tickets_in_hand' },
    { data: 'date_sold' },
    { data: 'site_sold' },
    { data: 'sale_id_ref' },
    { data: 'refund' },
    { data: 'sell_price' },
    { data: 'profit' },
    { data: 'delivered' },
    { data: 'delivered_status' },
    { data: 'delivered_date' },
    { data: 'paid' },
    { data: 'paid_date' },
    { data: 'paid_amount' },
];

const defaultSearchInputs = [
    'transaction_number',
    'order_number',
    'event_date',
    'event',
    'arena',
    'site_purchased',
    'type_of_ticket',
    'restrictions',
    'seats',
    'referral_1',
    'user_code',
    'site_sold',
    'sale_id_ref'
];

const defaultSearchSelects = {
    delivered: {
        options: {
            All: '',
            Y: 'Y',
            N: 'N'
        }
    },
    pending_cancelled: {
        options: {
            All: '',
            Y: 'Y',
            N: 'N'
        }
    },
    listed_on_resale: {
        options: {
            All: '',
            Y: 'Y',
            N: 'N'
        }
    }
}

function getIndexHiddenColumnsTable(hiddenColumns) {
    let columnsIndex = [];

    defaultColumnsTable.map((item, index) => {
        if(hiddenColumns.includes(item.data)){
            columnsIndex.push(index);
        }
    });

    return columnsIndex;
}

function getIndexHiddenInventoryColumnsTable(hiddenColumns) {
    let columnsIndex = [];

    inventoryColumnsTable.map((item, index) => {
        if(hiddenColumns.includes(item.data)){
            columnsIndex.push(index);
        }
    });

    return columnsIndex;
}

/**
 * All tickets table
 */
const columnNoVisibleAllTickets = ['all', 'available', 'number_of_tickets', 'notes', 'sold'];
const hiddenExportDataColumnsAllTickets = ['all', 'sold'];
$('#all-tickets-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: 50,
	processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
	ajax: {
        url: '/admin/tickets/all/datatable',
        data: {
            hiddenExportDataColumns: hiddenExportDataColumnsAllTickets
        }
    },
	columns: defaultColumnsTable.concat({ data: 'ticket_state' }),
    columnDefs: [
        { visible: false, targets: getIndexHiddenColumnsTable(columnNoVisibleAllTickets) },
    ],
    bFilter: false,
    initComplete: function() {
        //addSearchInputs(this.api(), defaultSearchInputs)
    },
    drawCallback: function() {
        //addEventTicketsInHand(this.api(), 'inventoryInHand');
    },
}));

/**
 * Inventory table
 */
const inventoryColumnsTable = [
    { data: 'all' },
    { data: 'batch_id' },
    { data: 'transaction_number' },
    { data: 'order_number' },
    { data: 'event' },
    { data: 'arena' },
    { data: 'site_purchased' },
    { data: 'type_of_ticket' },
    { data: 'purchase_date' },
    { data: 'event_date' },
    { data: 'time_of_event' },
    { data: 'day_of_event' },
    { data: 'available_d' },
    { data: 'available' },
    { data: 'number_of_tickets_d' },
    { data: 'number_of_tickets' },
    { data: 'notes' },
    { data: 'pending_cancelled' },
    { data: 'listed_on_resale' },
    { data: 'restrictions' },
    { data: 'facevalue_per_ticket' },
    { data: 'seats' },
    { data: 'listed' },
    { data: 'referral_1' },
    { data: 'referral_2' },
    { data: 'user_code' },
    { data: 'user_name' },
    { data: 'user_deposit' },
    { data: 'sold' },
    { data: 'total_cost_per_ticket' },
    { data: 'total_cost' },
    { data: 'tickets_in_hand' },
    { data: 'date_sold' },
    { data: 'site_sold' },
    { data: 'sale_id_ref' },
    { data: 'refund' },
    { data: 'sell_price' },
    { data: 'profit' },
    { data: 'delivered' },
    { data: 'delivered_status' },
    { data: 'delivered_date' },
    { data: 'paid' },
    { data: 'paid_date' },
    { data: 'paid_amount' },
];
const columnNoVisibleInventory = ['all', 'batch_id', 'purchase_date', 'available', 'number_of_tickets', 'notes', 'sold', 'date_sold', 'site_sold', 'sale_id_ref', 'refund', 'sell_price', 'profit', 'delivered', 'delivered_status', 'delivered_date', 'paid', 'paid_date', 'paid_amount'];
const hiddenExportDataColumnsInventory = ['all', 'batch_id', 'purchase_date', 'sold', 'date_sold', 'site_sold', 'sale_id_ref', 'refund', 'sell_price', 'profit', 'delivered', 'delivered_status', 'paid', 'paid_date'];
$('#inventory-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: 50,
	processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
	ajax: {
        url: '/admin/inventory/datatable',
        data: {
            hiddenExportDataColumns: hiddenExportDataColumnsInventory
        }
    },
	columns: inventoryColumnsTable.concat({ data: 'inventory_actions' }),
    columnDefs: [
        { visible: false, targets: getIndexHiddenInventoryColumnsTable(columnNoVisibleInventory) },
    ],
    initComplete: function() {
        addSearchInputs(this.api(), defaultSearchInputs)
        addSearchSelects(this.api(), defaultSearchSelects)
    },
    drawCallback: function() {
        addEventTicketsInHand(this.api(), 'inventoryInHand');
        addEventExpiredTickets(this.api());
    },
}));

/**
 * Sold table
 */
const columnNoVisibleSold = ['batch_id', 'purchase_date', 'available_d', 'available', 'number_of_tickets', 'notes', 'sold', 'refund', 'delivered_date', 'paid', 'paid_date', 'paid_amount'];
const hiddenExportDataColumnsSold = ['batch_id', 'purchase_date', 'available_d', 'available', 'sold', 'refund', 'delivered_date', 'paid', 'paid_date'];
$('#sold-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: 50,
	processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
	ajax: {
        url: '/admin/sold/datatable',
        data: {
            hiddenExportDataColumns: hiddenExportDataColumnsSold
        }
    },
	columns: defaultColumnsTable.concat({ data: 'sold_actions' }),
    columnDefs: [
        { visible: false, targets: getIndexHiddenColumnsTable(columnNoVisibleSold) },
    ],
    initComplete: function() {
        addSearchInputs(this.api(), defaultSearchInputs)
        addSearchSelects(this.api(), defaultSearchSelects)
    },
    drawCallback: function() {
        addEventInputDeliveredStatus(this.api());
        addEventTicketsInHand(this.api(), 'ticketsInHand');
    },
 }));

 /**
 * Delivery table
 */
const columnNoVisibleDelivery = ['purchase_date', 'available_d', 'available', 'number_of_tickets', 'notes', 'restrictions', 'tickets_in_hand', 'date_sold', 'refund', 'delivered_status', 'paid_amount'];
const hiddenExportDataColumnsDelivery = ['purchase_date', 'available_d', 'available', 'restrictions', 'tickets_in_hand', 'date_sold', 'refund', 'delivered_status'];
$('#delivered-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: 50,
	processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
	ajax: {
        url: '/admin/delivered/datatable',
        data: {
            hiddenExportDataColumns: hiddenExportDataColumnsDelivery
        }
    },
	columns: defaultColumnsTable.concat({ data: 'delivered_actions' }),
    columnDefs: [
        { visible: false, targets: getIndexHiddenColumnsTable(columnNoVisibleDelivery) },
    ],
    initComplete: function() {
        addSearchInputs(this.api(), defaultSearchInputs)
        addSearchSelects(this.api(), defaultSearchSelects)
    },
    drawCallback: function() {
        addEventInputDeliveredStatus(this.api());
        addEventInputPaid(this.api());
    },
 }));

/**
 * Completed table
 */
const columnNoVisibleCompleted = ['batch_id', 'available_d', 'available', 'number_of_tickets', 'notes', 'sold', 'tickets_in_hand', 'delivered_status'];
const hiddenExportDataColumnsCompleted = ['batch_id', 'available_d', 'available', 'sold', 'tickets_in_hand', 'delivered_status'];
$('#completed-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: 50,
	processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
    ajax: {
        url: '/admin/completed/datatable',
        data: {
            hiddenExportDataColumns: hiddenExportDataColumnsCompleted
        }
    },
	columns: defaultColumnsTable.concat({ data: 'completed_actions' }),
    columnDefs: [
        { visible: false, targets: getIndexHiddenColumnsTable(columnNoVisibleCompleted) },
    ],
    initComplete: function() {
        addSearchInputs(this.api(), defaultSearchInputs)
        addSearchSelects(this.api(), defaultSearchSelects)
    },
    drawCallback: function() {
        addEventInputDeliveredStatus(this.api());
        addEventInputPaid(this.api());
    },
 }));

/**
 * Fulfillment board table
 */

const fulfillmentBoardColumnsTable = [
    { data: 'event_details' },
    { data: 'site_purchased' },
    { data: 'paper' },
    { data: 'unsold' },
    { data: 'comparable' },
    { data: 'to_be_fulfilled' },
    { data: 'fulfillment_summary' },
    { data: 'fulfillment_total' },
    { data: 'deadline' },
    { data: 'site_sold' },
]

const fulfillmentBoardSearchInputs = [
    'event_details',
    'site_purchased',
    'to_be_fulfilled',
    'fulfillment_summary'
];

const fulfillmentBoardSearchCheckboxes = [
    'paper',
    'unsold',
    'comparable',
    'to_be_fulfilled'
]

let fulfillmentBoardPageLength = 30,
    eventFulfillmentIndex = parseInt(new URLSearchParams(document.location.search).get("event-fulfillment-index"));

$('#fulfillment-board-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: fulfillmentBoardPageLength,
    displayStart: isNaN(eventFulfillmentIndex) ? 0 : Math.floor(eventFulfillmentIndex / 30) * 30,
    processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
    ajax: {
        url: '/admin/fulfillment-board/datatable'
    },
    columns: fulfillmentBoardColumnsTable.concat({ data: 'fulfillment_actions' }),
    columnDefs: [
        {
            "targets": 2,
            "className": "text-center",
        },
        {
            "targets": 3,
            "className": "text-center align-bottom",
        },
        {
            "targets": 7,
            "className": "text-center align-bottom",
        },
        {
            "targets": 8,
            "className": "text-center align-middle",
        },
        {
            "targets": 10,
            "className": "td-no-padding align-middle"
        }
    ],
    initComplete: function() {
        addSearchInputs(this.api(), fulfillmentBoardSearchInputs)
        addSearchCheckboxes(this.api(), fulfillmentBoardSearchCheckboxes)
        if (!isNaN(eventFulfillmentIndex)) {
            var row = this.DataTable().row(eventFulfillmentIndex % fulfillmentBoardPageLength).node()
            $([document.documentElement, document.body]).animate({
                scrollTop: $(row).offset().top - 100
            }, 250, function() {
                $(row).addClass('highlight');

                setTimeout(function() {
                    $(row).removeClass('highlight');
                }, 1000);
            });
        }
    },
}));

 /**
 * Datatables Purchases columns
 */
 const columnsPurchasesTable = [
    { data: 'all' },
    { data: 'transaction_number' },
    { data: 'order_number' },
    { data: 'event_genre' },
    { data: 'event' },
    { data: 'arena' },
    { data: 'country' },
    { data: 'site_purchase' },
    { data: 'type_of_ticket' },
    { data: 'purchased_date' },
    { data: 'event_date' },
    { data: 'time_of_event' },
    { data: 'day_of_event' },
    { data: 'number_of_tickets' },
    { data: 'restrictions' },
    { data: 'seats' },
    { data: 'facevalue_per_ticket' },
    { data: 'facevalue_of_tickets' },
    { data: 'buyers_fees' },
    { data: 'user_code' },
    { data: 'user_deposit' },
    { data: 'user_name' },
    { data: 'total_cost_per_ticket' },
    { data: 'total_cost' },
    { data: 'tickets_in_hand' },
    { data: 'broken_limit' },
    { data: 'insurance' },
    { data: 'inputer' },
    { data: 'pending_reconciled' },
    { data: 'reconciled' },
    { data: 'state_ticket' },
    { data: 'refund' },
    { data: 'paid_amount' },
];

const searchPurchasesInputs = [
    'transaction_number',
    'order_number',
    //'event_genre',
    'event',
    'arena',
    'country',
    'site_purchase',
    'type_of_ticket',
    //'purchased_date',
    'event_date',
    //'time_of_event',
    //'day_of_event',
    'number_of_tickets',
    //'restrictions',
    'seats',
    //'facevalue_per_ticket',
    //'facevalue_of_tickets',
    //'buyers_fees',
    'user_code',
    //'user_deposit',
    'user_name',
    //'total_cost_per_ticket',
    //'total_cost',
    //'tickets_in_hand',
    'broken_limit',
    'insurance',
    'inputer',
    'pending_reconciled',
    'reconciled',
    //'state_ticket',
    //'refund',
    //'paid_amount',
];

/**
 * Purchases table
 */
$('#purchases-table').DataTable( Object.assign({}, commonOptions, {
    pageLength: 50,
	processing: true,
    serverSide: true,
    stateSave: false,
    serverMethod: 'post',
    ajax: {
        url: '/admin/purchases/datatable',
        dataSrc: function (json){
            if(json.totalCostFiltered !== undefined){
                $('.filtered-cost').html(number_format(parseFloat(json.totalCostFiltered), 2, '.', ','))
            }

            return json.aaData;
        },
    },

	columns: columnsPurchasesTable,
    initComplete: function() {
        addSearchInputs(this.api(), searchPurchasesInputs)
        //calculatePurchaseTotals(this.api())
    },
    drawCallback: function() {
        updateShowingStatus()
    }
 }));

/**
 *
 */
function getDataTable($elem) {
    if ($.fn.dataTable.isDataTable($elem)) {
        return $elem.dataTable()
    } else {
        return undefined;
    }
}

/**
 *
 */
let debouncedSearchInColumn = _.debounce((apiColumn, val) => {
    if (apiColumn.search() !== val) {
        apiColumn.search(val, false, false).draw()
    }
    updateShowingStatus(apiColumn.table().container())
}, 100)

/**
 *
 */
function updateShowingStatus() {
    $('.showing-status').text($('.dataTables_info').text());
}

/**
 *
 */
// TODO add new param
function addSearchInputs(dataTable, names = undefined) {
    let elem = dataTable.table().container()

    // get visible columns
    let aoColumns = dataTable.settings()[0].aoColumns.filter(aoColumn => aoColumn.bVisible)
    // find visible th
    $(elem).find('thead tr:eq(0):not(.d-none) th').each( function (i) {
        let title = $(this).text();
        let name = aoColumns[i].mData
        let table = getDataTable($(this).parents('.table-searchable'))

        if (title.toLowerCase().indexOf('all') != -1 || table === undefined || (names && !names.includes(name))) { return }

        // add input and clear api
        if($(this).hasClass('datepicker')){
            const altDateClass = 'alt-date-'+ i;
            let clearDateButton = $('<button class="btn btn-info text-white buttons-html5"></button>');
            let inputDatePicker = $('<input type="text" class="searchFilter" placeholder="" autocomplete="off" />');
            const inputAltField = $('<input type="text" class="' + altDateClass + '" placeholder="" />');

            inputDatePicker.datepicker({
                dateFormat: 'yy-mm-dd',
                altFormat: 'dd/mm/yy',
                altField: '.' + altDateClass,
                maxDate: '+2y +3m',
                changeMonth: true,
                changeYear: true,
                monthNamesShort: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            });

            clearDateButton.on('click', function () {
                inputDatePicker.datepicker('setDate', null).change();
            });

            $(this).html('').append(clearDateButton, title, inputDatePicker, inputAltField);
        } else{
            $(this).html(title + '<input type="text" class="searchFilter" placeholder="" />')
        }

        $('input', this ).on('keyup change clear', function() {
            debouncedSearchInColumn(dataTable.column(aoColumns[i].idx), this.value)
            //$('.fitterCosts').text($('.dataTables_info').text());
        })
    });
}

/**
 *
 */
function addSearchSelects(dataTable, names = {}) {
    let elem = dataTable.table().container()

    // get visible columns
    let aoColumns = dataTable.settings()[0].aoColumns.filter(aoColumn => aoColumn.bVisible)
    // find visible th
    $(elem).find('thead tr:eq(0):not(.d-none) th').each( function (i) {
        let title = $(this).text();
        let name = aoColumns[i].mData
        let table = getDataTable($(this).parents('.table-searchable'))

        if (title.toLowerCase().indexOf('all') != -1 || table === undefined || (names && !Object.keys(names).includes(name))) { return }

        let options = ''
        $.each(names[name].options, function (value, key) {
            options += "<option value='" + key + "'>" + value + "</option>"
        })
        let select = '<select id="' + name + '" name="' + name + '">' + options + '</select>'

        $(this).html(title + select)

        $('select', this ).on('keyup change clear', function() {
            debouncedSearchInColumn(dataTable.column(aoColumns[i].idx), this.value)
        })
    });
}

/**
 *
 */
function addSearchCheckboxes(dataTable, names = {}) {
    let elem = dataTable.table().container()

    // get visible columns
    let aoColumns = dataTable.settings()[0].aoColumns.filter(aoColumn => aoColumn.bVisible)
    // find visible th
    $(elem).find('thead tr:eq(0):not(.d-none) th').each( function (i) {
        let title = $(this).text();
        let name = aoColumns[i].mData
        let table = getDataTable($(this).parents('.table-searchable'))

        if (title.toLowerCase().indexOf('all') != -1 || table === undefined || (names && !names.includes(name))) { return }

        let checkbox = '<input type="checkbox" id="' + name + '" name="' + name + '"/>'

        $(this).append(checkbox)

        $( 'input[type="checkbox"]', this ).on('change', function() {
            debouncedSearchInColumn(dataTable.column(aoColumns[i].idx), this.checked ? 'checked' : 'unchecked')
        })
    });
}

/**
 *
 */
function addEventInputDeliveredStatus(dataTable){
    let elem = dataTable.table().container();
    $(elem).find('input.ticketHandlingRevers').each( function (i) {
        $(this).on('change', function() {
            var id = $(this).data('id');
            var hide = $(this).data('hide');
            if($(this).is(":checked")){
                var status = 3;
            }
            else if($(this).is(":not(:checked)")){
                var  status = 1;
            }
            $.get('/admin/tickets/handling/'+id+'/'+status).then((response) => {
                if(hide) {
                    dataTable.row($(this).parents('tr')).remove().draw();
                }
            });
        });
    });
}

/**
 *
 */
function addEventTicketsInHand(dataTable, classInput){
    let elem = dataTable.table().container();
    $(elem).find('input.' + classInput).each( function (i) {
        var getUrl = classInput == 'inventoryInHand'? '/admin/tickets/inventoryinhand/': (classInput == 'ticketsInHand'? '/admin/tickets/ticketsinhand/': '');
        if(getUrl.length > 0){
            $(this).on('change', function() {
                var id =$(this).data('id');
                if($(this).is(":checked")){
                    var status = 2;
                }
                else if($(this).is(":not(:checked)")){
                    var  status = 1;
                }

                $.get(getUrl+id+'/'+status)
                .fail(function(error) {
                    if (error.status == 422) {
                        alert(error.responseJSON.errors.status[0])
                    }
                });
            });
        }
    });
}

/**
 *
 */
function addEventInputPaid(dataTable){
    let elem = dataTable.table().container();
    $(elem).find('input.isPaid').each( function (i) {
        $(this).on('change', function() {
            var id =$(this).data('id');
            if($(this).is(":checked")){
                var status = 2;
            }
            else if($(this).is(":not(:checked)")){
                var  status = 1;
            }

            $.get('/api/ticketInfo/sold/isPaid/'+id+'/'+status).then((response) =>{
                dataTable.row($(this).parents('tr')).remove().draw();
            });
        });
    });
}

function addEventExpiredTickets(dataTable){
    let elem = dataTable.table().container();
    $(elem).find('button.expiredTickets').each( function (i) {
        $(this).on('click', function() {
            var id =$(this).data('id');

            Swal.fire({
                title: 'Are you sure?',
                text: "This will move tickets to the Adjustments / Expired Tickets section",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.value) {
                    $.get('/api/ticketInfo/expire/'+id).then((response) =>{
                        Swal.fire(
                            'Expired!',
                            'Your ticket has been sent to Adjustments / Expired Tickets',
                            'success'
                        )
                        dataTable.row($(this).parents('tr')).remove().draw();
                    });
                }
            });
        });
    });
}

/**
 *
 */
function calculatePurchaseTotals(dataTable) {

    // Remove the formatting to get integer data for summation
    let intVal = (i) => typeof i === 'string' ? i.replace(/[\£,]/g, '')*1 : typeof i === 'number' ? i : 0

    // Total over all pages
    let total = dataTable.column(23).data().reduce((a, b) => intVal(a) + intVal(b), 0)

    // Total over this page
    let pageTotal = dataTable.column(23, { page: 'current'} ).data().reduce((a, b) => intVal(a) + intVal(b), 0)

    // Update footer
    $(dataTable.column(23).footer() ).html(
        '£<span class="total-filter">' + number_format(pageTotal, 2, '.', ',') + '</span> ( £<span class="totalAll">' + number_format(total, 2, '.', ',') + '<span> total)'
    )
    $('.total-cost').html(number_format(total, 2, '.', ','))
    $('.filtered-cost').html(number_format(pageTotal, 2, '.', ','))
}

/**
 *
 */
function newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    dt.one('preXhr', function (e, s, data) {
        // Just this once, load all data from the server...
        data.start = 0;
        data.length = 2147483647;
        dt.one('preDraw', function (e, settings) {
            // Call the original action function
            if (button[0].className.indexOf('buttons-copy') >= 0) {
                $.fn.dataTable.ext.buttons.copyHtml5.action.call(self, e, dt, button, config);
            } else if (button[0].className.indexOf('buttons-excel') >= 0) {
                $.fn.dataTable.ext.buttons.excelHtml5.available(dt, config) ?
                    $.fn.dataTable.ext.buttons.excelHtml5.action.call(self, e, dt, button, config) :
                    $.fn.dataTable.ext.buttons.excelFlash.action.call(self, e, dt, button, config);
            } else if (button[0].className.indexOf('buttons-csv') >= 0) {
                $.fn.dataTable.ext.buttons.csvHtml5.available(dt, config) ?
                    $.fn.dataTable.ext.buttons.csvHtml5.action.call(self, e, dt, button, config) :
                    $.fn.dataTable.ext.buttons.csvFlash.action.call(self, e, dt, button, config);
            } else if (button[0].className.indexOf('buttons-pdf') >= 0) {
                $.fn.dataTable.ext.buttons.pdfHtml5.available(dt, config) ?
                    $.fn.dataTable.ext.buttons.pdfHtml5.action.call(self, e, dt, button, config) :
                    $.fn.dataTable.ext.buttons.pdfFlash.action.call(self, e, dt, button, config);
            } else if (button[0].className.indexOf('buttons-print') >= 0) {
                $.fn.dataTable.ext.buttons.print.action(e, dt, button, config);
            }
            dt.one('preXhr', function (e, s, data) {
                // DataTables thinks the first item displayed is index 0, but we're not drawing that.
                // Set the property to what it was before exporting.
                settings._iDisplayStart = oldStart;
                data.start = oldStart;
            });
            // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
            setTimeout(dt.ajax.reload, 0);
            // Prevent rendering of the full data to the DOM
            return false;
        });
    });

    var oldButtonHTML = button.html()
    button.text('EXPORTING ...')
    button.attr('disabled', true)

    dt.one('draw', function (e, s, data) {
        button.html(oldButtonHTML)
        button.removeAttr('disabled')
    })

    // Requery the server with the new one-time export settings
    dt.ajax.reload()
}
