require('./bootstrap')
require('./formatters')
require('./sb-admin-2')
require('./datatables')
require('./sweetalert')
require('./selectize')
require('./datepicker')
require('./toggles')
require('./scripts')

// google-chrome --ignore-certificate-errors --unsafely-treat-insecure-origin-as-secure=https://dev.ttanalytics.net --user-data-dir=/tmp/foo
// uncommented out for debugging of Firebid Cloud Messaging
// require('./fcm')
