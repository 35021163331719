$('.process').click(function () {
    $(this).text('in progress').attr('disabled','yes')
    window.location = $(this).attr('href');
});

$('.ticketsInHand').change(function() {
    var id =$(this).data('id');
    if($(this).is(":checked")){
        var status = 2;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }
    $.get('/admin/tickets/ticketsinhand/'+id+'/'+status)
    .fail(function(error) {
        if (error.status == 422) {
            alert(error.responseJSON.errors.status[0])
        }
    });
});

$('.inventoryInHand').change(function() {
    var id =$(this).data('id');
    if($(this).is(":checked")){
        var status = 2;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }
    $.get('/admin/tickets/inventoryinhand/'+id+'/'+status)
    .fail(function(error) {
        if (error.status == 422) {
            alert(error.responseJSON.errors.status[0])
        }
    });
});


$('.isPaid').change(function() {

    var id =$(this).data('id');
    if($(this).is(":checked")){
        var status = 2;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }

    $.get('/api/ticketInfo/sold/isPaid/'+id+'/'+status).then((response) =>{
        if(response.is_paid == 'Y'){
            var table = $('#dataTableTickets').DataTable();
            table .row($(this).parents('tr'))
                .remove()
                .draw();

            return false;

        }else{
            var table = $('#dataTableTickets').DataTable();
            table .row($(this).parents('tr')).remove()
                .draw();
            return false;
        }
    });
});

$('.ticketsInSold').change(function() {

    var id =$(this).data('id');
    if($(this).is(":checked")){
        var status = 3;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }
    $.get('/api/ticketInfo/sold/isSold/'+id+'/'+status).then((response) =>{

        if(response.ticket_status_id == "1"){
            var table = $('#dataTableTickets').DataTable();
            table .row($(this).parents('tr')).remove().draw();
            return false;
        }else{
            var table = $('#dataTableTickets').DataTable();
            table .row($(this).parents('tr')).remove().draw();
            return false;
        }
    });
});

$('.ticketHandlingRevers').change(function() {

    var id = $(this).data('id');
    var hide = $(this).data('hide');
    if($(this).is(":checked")){
        var status = 3;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }
    $.get('/admin/tickets/handling/'+id+'/'+status).then((response) => {
        if(hide) {
            var table = $('#dataTableTickets').DataTable();
            table .row($(this).parents('tr')).remove().draw();
            return false;
        }
    });
});

$('.chase').change(function() {
    var id =$(this).data('id');
    if($(this).is(":checked")){
        var status = 2;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }
    $.get('/api/ticketInfo/notrecived/chase/'+id+'/'+status);
});


$('.pic').change(function() {
    var id =$(this).data('id');
    if($(this).is(":checked")){
        var status = 2;
    }
    else if($(this).is(":not(:checked)")){
        var  status = 1;
    }
    $.get('/api/ticketInfo/notrecived/pic/'+id+'/'+status);
});


$("#checkall").click(function() {
    if($("#checkall").prop("checked") == true){

        $('.selectCheck').each(function() {
             $(this).prop("checked", true)

            var array = $.map($('.selectCheck:checked'), function(c){return c.value; })
            $('#massSelection').val(JSON.stringify(array));

        })
    }
    else if($("#checkall").prop("checked") == false){
        $('.selectCheck').each(function() {
            $(this).prop("checked", false)

            $('#massSelection').val('');


        })
    }
});


$(".selectCheck").click(function() {
        $('.selectCheck').each(function() {
                var array = $.map($('.selectCheck:checked'), function(c){return c.value; })
                $('#massSelection').val(JSON.stringify(array));

        })
});


window.availableForSwap = function(id, elem) {
    $.post(`swap/availableForSwap/${id}`,{
        '_token':  $("meta[name='csrf-token']").attr('content'),
    },
    function() {
        if (elem) {
            let dataTable = $(elem).parents('table').DataTable();
            if (dataTable) {
                dataTable.row($(elem).parents('tr')).remove().draw();
            }
        }
    });
}

window.returnToAvailable = function(id, elem) {
    $.post(`swap/returnToAvailable/${id}`,{
        '_token':  $("meta[name='csrf-token']").attr('content'),
    },
    function() {
        if (elem) {
            let dataTable = $(elem).parents('table').DataTable();
            if (dataTable) {
                dataTable.row($(elem).parents('tr')).remove().draw();
            }
        }
    });
}

$('.table').on('click', '.pendingCancel', function() {
    var id =$(this).data('id');

    Swal.fire({
        title: 'Are you sure?',
        text: "This will make inventory pending cancelled",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $.get('/api/ticketInfo/pendingCancel/'+id).then((response) => {
                Swal.fire(
                    'Cancelled!',
                    'Your inventory is pending cancelled',
                    'success'
                )
                $(this).removeClass('pendingCancel btn-sm')
                    .addClass('restorePendingCancelled btn-danger')
                    .text('YES');
            });
        }
    })
});

$('.table').on('click', '.restorePendingCancelled', function() {
    var id =$(this).data('id');

    Swal.fire({
        title: 'Are you sure?',
        text: "This will restore inventory from being pending cancelled",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $.get('/api/ticketInfo/restorePendingCancelled/'+id).then((response) => {
                Swal.fire(
                    'Restored!',
                    'Your inventory has been restored from being pending cancelled',
                    'success'
                )
                $(this).removeClass('restorePendingCancelled btn-danger')
                    .addClass('pendingCancel btn-sm')
                    .text('NO');
            });
        }
    })
});

$('.table').on('click', '.listOnResale', function() {
    var id =$(this).data('id');

    Swal.fire({
        title: 'Are you sure?',
        text: "This will list ticket on resale",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $.get('/api/ticketInfo/listOnResale/'+id).then((response) => {
                Swal.fire(
                    'Done!',
                    'Your ticket has been listed on resale',
                    'success'
                )
                var table = $('#dataTableTickets').DataTable();
                $(this).removeClass('listOnResale btn-sm')
                    .addClass('unlistOnResale btn-warning')
                    .text('YES');
            });
        }
    })
});

$('.table').on('click', '.unlistOnResale', function() {
    var id =$(this).data('id');

    Swal.fire({
        title: 'Are you sure?',
        text: "This will restore event from being unlisted",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $.get('/api/ticketInfo/unlistOnResale/'+id).then((response) => {
                Swal.fire(
                    'Restored!',
                    'Your ticket has been restored from being unlisted',
                    'success'
                )
                var table = $('#dataTableTickets').DataTable();
                $(this).removeClass('unlistOnResale btn-warning')
                    .addClass('listOnResale btn-sm')
                    .text('NO');
            });
        }
    })
});

$('.table').on('click', '.cancel_sale', function () {
    let id = $(this).data('id');
    const ipAPI = '/admin/tickets/returntostock/'+id;

    Swal.fire({
         title: 'Are you sure? ',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, Cancel it!'
    })
    .then((result) => {
        if (result.value) {
            return fetch(ipAPI)
                .then((response) => {
                    Swal.fire(
                        'Cancelled!',
                        'The Ticket  has been Cancelled.',
                        'success'
                    )
                    var table = $(this).parents('table').DataTable();
                    table.row($(this).parents('tr')).remove().draw()
                    return false;
                })
        }
    });
});

$('.expiredTickets').click(function() {
    var id =$(this).data('id');

    Swal.fire({
        title: 'Are you sure?',
        text: "This will move tickets to the Adjustments / Expired Tickets section. You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $.get('/api/ticketInfo/expire/'+id).then((response) =>{
                Swal.fire(
                    'Expired!',
                    'Your ticket has been sent to Adjustments / Expired Tickets',
                    'success'
                )
                var table = $('#dataTableTickets').DataTable();
                     table .row($(this).parents('tr'))
                            .remove()
                            .draw();
            });
        }
    })
});

$('.restoreTicket').click(function() {
    var id =$(this).data('id');
    console.log('restore ticket ' + id);

    Swal.fire({
        title: 'Are you sure?',
        text: "This will restore ticket from being expired",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $.get('/api/ticketInfo/restoreExpired/'+id).then((response) =>{
                Swal.fire(
                    'Restored!',
                    'Your ticket has been restored',
                    'success'
                )
                var table = $('.dataTable').DataTable();
                table .row($(this).parents('tr'))
                    .remove()
                    .draw();
            });
        }
    })
});

/* submit with alert */
$('button.submit-with-danger').click(function() {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $(this).parents('form').submit();
        }
    })
});


/* selectize inputs */
$('.desired-events').selectize({
    delimiter: ',',
    persist: false,
    create: function(input) {
        return {
            value: input,
            text: input
        }
    }
});

$('.platform-user-id').selectize({
    create: false,
    sortField: 'text'
});

$('.platform-user-code').selectize({
    create: false,
    sortField: 'text'
});

/* datatables */
// Call the dataTables jQuery plugin
$(document).ready(function() {

    // Default table
    var defaultTable =  $('#dataTableDefault').DataTable({
        order: [],
        orderCellsTop: true,
        cache: false,
        stateSave: true,
        fixedHeader: true,
        language: {
            decimal: ".",
            thousands: ","
        },
        pageLength: 100,
        aButtons : [
            {
                sExtends: "select_all",
                sButtonText: "Select Filtered",
                fnClick: function (nButton, oConfig, oFlash) {
                    var oTT = TableTools.fnGetInstance('dataTable');
                    oTT.fnSelectAll(true); //True = Select only filtered rows (true). Optional - default false.
                }

            }],
        dom: "Bfrtip",
        columnDefs: [
            { orderable: false, targets: 0 }
        ],
        buttons: {
            buttons: [
                {
                    extend: 'colvis',  className: 'btn-colvis',
                    columns: ':not(.noVis)'
                },
                {
                    extend: 'csvHtml5',
                    className: 'btn-csv',
                    text: 'CSV ',
                    exportOptions: {
                        modifier: {
                            search: 'none'
                        }
                    }
                },
                'excelHtml5'
            ],
            dom: {
                button: {
                    className: 'btn btn-info btn-sm text-white',
                }
            }
        }
    });

    //Standard Tickets table without sum value calculations
    var table =  $('#dataTableTickets').DataTable({
        ordering: false,
        orderCellsTop: false,
        cache: false,
        stateSave: false,
        fixedHeader: false,
        language: {
            decimal: ".",
            thousands: ","
        },
        pageLength: 100,
        aButtons : [{
            sExtends: "select_all",
            sButtonText: "Select Filtered",
            fnClick: function (nButton, oConfig, oFlash) {
                var oTT = TableTools.fnGetInstance('dataTable');
                oTT.fnSelectAll(true); //True = Select only filtered rows (true). Optional - default false.
            }
        }],
        dom: 'Bfrtip',
        columnDefs: [
            { type: 'date-uk', targets: 6 },
            { orderable: false, targets: "_all" },
        ],
        buttons: {
            buttons: [
                { extend: 'colvis', className: 'btn-colvis', columns: ':not(.noVis)' },
                { extend: 'excelHtml5', autoFilter: true, sheetName: 'Exported data', text:'EXCEL', exportOptions:{columns: ':not(.not-export)'}}
            ],
            dom: {
                button: {
                    className: 'btn btn-info btn-sm text-white',
                }
            }
        },
    });

    // Finance table
    var financeTable =  $('#dataTableFinance').DataTable({
        ordering: true,
        orderCellsTop: true,
        //stateSave: true,
        language: {
            decimal: ".",
            thousands: ","
        },
        pageLength: 100,
        //dom: "Bfrtip",
        dom:"<'row'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>>rtip",
        buttons: [ 'colvis', 'csvHtml5', 'excelHtml5'],
        // select to date-column
        initComplete: function () {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            let table = this.api();
            let $table = $(this);
            this.api().columns('#column_date').every( function () {
                let column = this;
                let header = column.header();
                let select = $("<select class='form-control form-control-sm'><option value=''></option></select>");
                select.appendTo($table.find('#select_date'));

                let $options = column.data().map(date => date.substr(0, 7)).unique().sort().reverse();
                $options.each(function (d, j) {
                    let year = +d.substr(0, 4);
                    let month = +d.substr(5, 2);
                    let text = '' + year + ' ' + months[month-1];
                    select.append('<option value="'+d+'">'+text+'</option>')
                });

                select.on('change', function () {
                    let val = $.fn.dataTable.util.escapeRegex(
                        $(this).val()
                    );

                    column.search( val ? '^'+val+'-' : '', true, false).draw();
                });

                select.find('option:eq(1)').prop('selected', true).trigger('change');
                table.order([column.index(), 'asc']).draw();
            });

            $(this).show();
        }
    });

    $('#massChange').click( function () {
        $.each(table.rows('.selected').data(),function (val) {
            table.rows('.selected').column(val).cell(0).data()
        });
    } );

    $('#dataTableDefault tbody').on( 'click', 'tr', function () {
        $('.selected').toggleClass('selected');
        $(this).toggleClass('selected');
    });

    $('#dataTableTickets:not(.multiple-rows) tbody').on( 'click', 'tr', function () {
        $('.selected').toggleClass('selected');
        $(this).toggleClass('selected');
    });

    $('#dataTableTickets.multiple-rows tbody').on( 'click', 'tr', function () {
        $(this).toggleClass('selected');
    });

    //////DATATABLE  TOGGLE  Columns  //////
    $('a.toggle-vis').on( 'click', function (e) {
        $(this).toggleClass('bg-danger text-white');
        e.preventDefault();
        // Get the column API object
        var column = table.column( $(this).attr('data-column') );
        // Toggle the visibility
        column.visible( ! column.visible() );
    });

    // all other tables
    $('#dataTableTickets thead tr:eq(0) th').each( function (i) {
        var title = $(this).text();
        if (title.toLowerCase().indexOf("all") == 1) {
        } else {
            $(this).html(title  + '<input type="text"  class="searchFilter" placeholder="" />');
            var search = $.fn.dataTable.util.throttle(function (val) {
                if (table.column(i).search() !== val) {
                    table.column(i).search(val, false, false).draw();
                }
            }, 1000);
            $('input', this).on('keyup change', function () {
                if ($('input', this).val()) {}
                if (table.column(i).search() !== this.value) {
                    if (table.column(i).search() !== this.value) {
                        search(this.value);
                    }
                }
            });
        }
    });

    $('#dataTableTickets thead tr:eq(0) th').each(function (i) {
        table
            .search('')
            .columns(i).search('')
            .draw();
    });

    // Purchase table  for filters & sum value calculations
    /*$('#dataTablePurchases thead tr:eq(0) th').each( function (i) {
        var title = $(this).text();
        if(title.toLowerCase().indexOf("all") == 1){
        } else {
            $(this).html( title +'<input type="text"  class="searchFilter" placeholder="" />' );
            var search = $.fn.dataTable.util.throttle(
                function ( val ) {
                    if (table2.column(i).search() !== val) {
                        table2.column(i).search(val, false, false).draw();
                    }
                },1000);
            $('input', this ).on('keyup change', function () {
                if ($('input', this).val()) {}
                if (table2.column(i).search() !== this.value) {
                    search(this.value);
                }
                $('.fitterCosts').text($('.dataTables_info').text());
            });
        }
    });*/

    /*$('#dataTablePurchases thead tr:eq(0) th').each( function (i) {
        table2
            .search('')
            .columns(i).search('')
            .draw();
    });*/

    table.order([ 6, 'asc' ] ).draw();

    $('input[type=search]').val();
    $('.searchFilter').val();
});

/* form VAT calculation */
let $amount = $('form #amount');
let $vat = $('form #vat');
let $amountInclVat = $('form #amount_incl_vat');

$amount.add($vat).on('change', function() {
    const amountInclVat = (parseFloat($amount.val()) || 0) + (parseFloat($vat.val()) || 0);
    $amountInclVat.val(amountInclVat.toFixed(2));
})

/* Mass updating event fulfillment forms */
$(".mass-update-form").submit( function(eventObj) {

    var form = $(this),
        values = ['inventory-event', 'inventory-arena', 'inventory-event-date', 'inventory-time-of-event']

    values.forEach(function (value) {

        $("<input />").attr("type", "hidden")
            .attr("name", value)
            .attr("value", $('#massUpdateModal').data(value))
            .appendTo(form);
    })

    return true;
});

$(".mass-update-form button[type='submit']").on('click', function (event) {
    event.preventDefault()
    event.stopPropagation()

    Swal.fire({
        title: 'Are you sure?',
        text: "This change will be applied to all related to event orders!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
            $(this).parents('form').submit();
        }
    })
})

$('.table').on('change', '.site-tickets-in-hand-checkbox', function (event) {
    event.preventDefault()
    event.stopPropagation()
    let inventoryQuantityIds = $(this).data('inventory-quantity-ids')

    Swal.fire({
        title: 'Are you sure?',
        text: 'This change will make all related orders ' + (!this.checked ? 'not' : '') + ' in hand!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value && result.isConfirmed) {
            $.post('fulfillment-board/massTicketsInHand', {
                '_token':  $("meta[name='csrf-token']").attr('content'),
                'inventory-quantity-ids': inventoryQuantityIds,
                'status': this.checked ? 2 : 1
            }).then((response) => {
                Swal.fire(
                    'Done!',
                    'Orders are ' + (!this.checked ? 'not' : '') + ' in hand now',
                    'success'
                )
                window.location.href = location.protocol + '//' + location.host + location.pathname + '?event-fulfillment-index=' + parseInt(response['eventFulfillmentIndex']);
            }).fail((response) => {
                if (response.status == 422 && response.responseJSON.errors.status[0]) {
                    Swal.fire(
                        'Failure.',
                        response.responseJSON.errors.status[0],
                        'error'
                    )
                }
            });
        } else {
            $(this).prop('checked', !this.checked)
        }
    })
})

$('.table').on('submit', '.event-fulfillment-export-form', function() {

    let row = $(this).parents('tr')

    $(this).find('input[name="inventory-quantity-ids"]').remove();

    if ($(row).find('.event-fulfillment-comparable-checkbox:checked').length) {
        let combinedArray = $(row).find('.event-fulfillment-comparable-checkbox:checked')
            .map(function() {
                return $(this).val();
            }).get()
            .reduce((acc, val) => acc.concat(JSON.parse(val)), []);

        $("<input />").attr("type", "hidden")
            .attr("name", "inventory-quantity-ids")
            .attr("value", combinedArray)
            .appendTo(this);

        $('<input />').attr('type', 'hidden')
            .attr('name', 'export-type')
            .attr('value', 'comparable')
            .appendTo(this)

        return true;
    } else if ($(row).find('.event-fulfillment-fulfilled-checkbox:checked').length) {
        let combinedArray = $(row).find('.event-fulfillment-fulfilled-checkbox:checked')
            .map(function() {
                return $(this).val();
            }).get()
            .reduce((acc, val) => acc.concat(JSON.parse(val)), []);

        $("<input />").attr("type", "hidden")
            .attr("name", "inventory-quantity-ids")
            .attr("value", combinedArray)
            .appendTo(this);

        $('<input />').attr('type', 'hidden')
            .attr('name', 'export-type')
            .attr('value', 'fulfilled')
            .appendTo(this)

        return true;
    }

    return false;
})

$('.table').on('change', '.event-fulfillment-comparable-checkbox', function () {
    let row = $(this).parents('tr'),
        exportBtn = $(row).find('.event-fulfillment-export-form button[type="submit"]')

    $(row).find('.event-fulfillment-fulfilled-checkbox').prop('checked', false)

    if ($(row).find('.event-fulfillment-comparable-checkbox:checked').length)
    {
        $(exportBtn).removeAttr('disabled')
    } else {
        $(exportBtn).attr('disabled', true)
    }
})

$('.table').on('change', '.event-fulfillment-fulfilled-checkbox', function () {
    let row = $(this).parents('tr'),
        exportBtn = $(row).find('.event-fulfillment-export-form button[type="submit"]')

    $(row).find('.event-fulfillment-comparable-checkbox').prop('checked', false)

    if ($(row).find('.event-fulfillment-fulfilled-checkbox:checked').length)
    {
        $(exportBtn).removeAttr('disabled')
    } else {
        $(exportBtn).attr('disabled', true)
    }
})
